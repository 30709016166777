<template lang="pug">
    .root.text-right
        .jumbotron.pull-right()
            .container-fluid.text-right
                .label
                    h2.moto-text הזמנה שלי - Buy Amazon Inc.
        .container.text-right
            .content
                .row.mt-3
                    .col-sm-12.col-md-8
                        .row
                            .col-12
                                h3 פרטים אישיים
                                .row
                                    .col-md-4.col-sm-12
                                        label שם
                                        b-input(readonly="", :value="entity.user.name")
                                    .col-md-4.col-sm-12
                                        label דוא"ל
                                        b-input(readonly="", :value="entity.user.email")
                                    .col-md-4.col-sm-12
                                        label מס' טלפון
                                        b-input(readonly="", :value="entity.user.phone")
                                .row.mt-5
                                    .col-md-8.col-sm-12
                                        label כתובת
                                        b-input(readonly="", :value="entity.user.address")



                            .col-12.mt-5.mb-5
                                h3 נתוני ההזמנה
                                .row
                                    .col-md-4.col-sm-12
                                        label מוצר
                                        b-input(readonly="", :value="entity.item_label")
                                    .col-md-4.col-sm-12
                                        label מס' הזמנה
                                        b-input(readonly="", :value="entity.order_number")
                                    .col-md-4.col-sm-12
                                        label תאריך הזמנה
                                        b-input(readonly="", :value="entity.createdAt")
                                .row.mt-5
                                    .col-md-4.col-sm-12
                                        label מספר למעקב
                                        b-input(readonly="", :value="entity.tracking_number")
                                    .col-md-4.col-sm-12
                                        label סטטוס
                                        b-input(readonly="", :value="entity.status")
                    .col-sm-12.col-md-4
                        h3 תמונת המוצר
                        .image-container
                            b-img(:src="entity.item_img_url")

</template>


<style lang="scss">
    .jumbotron {
        background-color: #091a38 !important;
        padding: 2rem 1rem !important;
        border-radius: 0 !important;

        h2.moto-text {
            font-family: "Helvetica Neue Light", "HelveticaNeue-Light", "Helvetica Neue", Calibri, Helvetica, Arial, sans-serif;
            font-weight: 600;
            font-size: 39px;
            color: #f9fbfd;

            span {
                color: orange;
            }

            small {
                font-size: 2rem;
                margin-right: 1rem;
                margin-top: 1rem;
                /*top: 10px;*/
            }
        }

        @media only screen and (max-width: 767px) {
            padding-top: 5rem !important;
        }

    }

    .container {
        .image-container {
            img {
                width: 100%;
            }
        }
    }
</style>


<script lang="js">
    import Vue from 'vue';
    export default {
        beforeRouteEnter(to, from, next) {
            if(to.name === 'inventar') {
                Vue.apix.sendHttpRequest('GET', 'package/get-data/', {uuid: localStorage.getItem('pkgid')})
                    .then(res => {
                        next(vm => {
                            vm.entity = res
                        })
                    })
                    .catch(err => {
                        window.location.replace('/');
                        return next(false);
                    })
            }
            else console.log(to)
        },
        data() {
            return {
                entity: {

                }
            }
        },
        methods: {
            fetchData() {
                this.$apix.sendHttpRequest('GET', 'package/get-data', {id: this.$store.state.LOGGED_PACKAGE})
            },

        },
        computed: {
            package() {
                return  this.$store.state.LOGGED_PACKAGE;
            },
            user() {
                return this.$store.state.LOGGED_USER;
            }
        }
    }

</script>
